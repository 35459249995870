<template>
  <v-container id="user-profile" fluid tag="section">
    <v-col cols="12" style="margin-bottom: -40px">
      <v-select
        @change="changeLang()"
        v-model="lang"
        :items="countries"
        label="Country"
        solo
      ></v-select>
    </v-col>
    <v-col cols="12" class="text-right">
      <v-btn
        @click="open_modal_category({ category: null })"
        class="mx-2"
        fab
        dark
        color="primary"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-col>
    <v-row justify="center">
      <entry-dialog
        @close-dialog="close_entry_dialog"
        :dialog2="dialog2"
        :item="newitem"
        :lang="lang"
        :edit_mode="entry_edit"
        :categoryId="categoryId"
        :subcategoryId_dialog="subcategoryId_dialog"
      ></entry-dialog>
      <category-dialog
        @close-dialog="close_category_dialog"
        :dialog1="dialog1"
        :category="category"
        :edit_mode="category_update"
        :lang="lang"
      ></category-dialog>
    </v-row>
    <draggable
      v-model="categories"
      group="categories"
      @start="drag = true"
      @end="drag = false"
    >
      <v-row
        justify="center"
        v-for="category in categories"
        v-bind:key="category.id"
      >
        <v-col cols="12" md="12">
          <category-component
            @open_modal_new_entry="open_modal_new_entry"
            @open_modal_edit_entry="open_modal_edit_entry"
            @open_modal_category="open_modal_category"
            :category="category"
            :lang="lang"
          ></category-component>
        </v-col>
      </v-row>
    </draggable>
  </v-container>
</template>

<script>
import * as firebase from "firebase";
import mime from "mime-types";
import draggable from "vuedraggable";

let default_cat = {
  id: 0,
  index: 0,
  en: {
    name: "",
  },
  sl: {
    name: "",
  },
  ge: {
    name: "",
  },
  dates: [],
};

let default_entry = {
  en: {
    name: "",
    description: "",
  },
  sl: {
    name: "",
    description: "",
  },
  ge: {
    name: "",
    description: "",
  },
  price: "",
  image: null,
  index: 0,
  dates: null,
};

export default {
  data() {
    return {
      lang: null,
      countries: [
        { text: "English", value: "en" },
        { text: "Slovenski", value: "sl" },
        { text: "German", value: "ge" },
      ],
      dialog1: false,
      dialog2: false,
      entry_edit: false,
      categoryId: null,
      newitem: default_entry,
      category: default_cat,
      category_update: false,
      subcategoryId_dialog: null,
    };
  },
  components: {
    "entry-dialog": () => import("../components/dialog/EntryDialog"),
    "category-dialog": () => import("../components/dialog/CategoryDialog"),
    "category-component": () => import("../components/custom/Category"),
    draggable,
  },
  mounted() {
    this.lang = this.$store.state.lang;
    this.$store.dispatch("FETCH_CATEGORIES");
  },
  computed: {
    categories: {
      get() {
        let cats = this.$store.state.categories.filter((cat) => {
          return cat.parentId == null;
        });

        return cats.sort((a, b) => {
          return a.index - b.index;
        });
      },
      set(value) {
        let cats = this.$store.state.categories;
        value.forEach((el, index) => {
          for (let i in cats) {
            if (el.id == cats[i].id) {
              cats[i].index = index;
            }
          }
        });

        cats.forEach((el) => {
          this.$store.dispatch("UPDATE_CATEGORY", { category: el });
        });
      },
    },
  },
  methods: {
    changeLang: function () {
      this.$store.commit("SET_LANG", this.lang);
      this.$store.dispatch("FETCH_CATEGORIES");
    },

    close_entry_dialog: function (event) {
      this.dialog2 = false;
      this.newitem = default_entry;
    },
    close_category_dialog: function (event) {
      this.dialog1 = false;
      this.category = default_cat;
    },
    open_modal_new_entry: function ({ categoryId }) {
      this.newitem = JSON.parse(JSON.stringify(default_entry));
      this.categoryId = categoryId;
      this.entry_edit = false;
      this.dialog2 = true;
    },
    open_modal_edit_entry: function ({ categoryId, item, itemId }) {
      item.image = null;
      this.newitem = JSON.parse(JSON.stringify(item));
      this.newitem.id = itemId;
      this.categoryId = categoryId;
      this.subcategoryId_dialog = this.categoryId;
      this.entry_edit = true;
      var storageRef = firebase.storage().ref();
      storageRef
        .child("meni/" + this.$store.state.authUser.id + "/" + itemId)
        .getDownloadURL()
        .then(
          (url) => {
            var xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.onload = (event) => {
              var blob = xhr.response;
              var fileExtension = mime.extension(blob.type);
              this.newitem.image = new File([blob], "image." + fileExtension, {
                type: blob.type,
              });
            };
            xhr.open("GET", url);
            xhr.send();
          },
          function () {}
        )
        .catch((e) => {});

      this.dialog2 = true;
    },
    open_modal_category: function ({ category, parentId }) {
      if (category != null) {
        this.category = JSON.parse(JSON.stringify(category));
        this.category_update = true;
      } else {
        this.category = JSON.parse(JSON.stringify(default_cat));
        this.category.parentId = parentId;
        this.category_update = false;
      }
      this.dialog1 = true;
    },
  },
};
</script>
